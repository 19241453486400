import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Sidebar from "../components/Sidebar";
import { getLangTerm } from "../lang";

const DownloadPage = ({pageContext}) => {

    const { lang } = pageContext;

    return <Layout
        pageContext={pageContext}
    >
        <Seo title="Try VanWalks For Free Right Now!" />
        <div className="sidebar-container">
            <Sidebar />
            <div className="sidebar-content text-content download-content">
                <Link
                    className="screenshot-link"
                    to={"/"}
                >
                    
                    <StaticImage
                        className="screenshot"
                        src="../images/home_screenshot_1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="VanWalks Screenshot"
                        objectFit="contain"
                        placeholder={"none"}
                    />
                </Link>
                <h2>VanWalks is now a web app!</h2>
                <p>You can enjoy VanWalks on any mobile device with a modern web browser. No apps to download, no accounts to create, no ads to watch.</p>
                <p>
                    <a
                        className="download-button"
                        href="https://map.vanwalks.ca"
                        target="_blank"
                        rel="noreferrer"
                    >
                        { getLangTerm("try-now", lang) }
                    </a></p>
            </div>
        </div>
    </Layout>
};

export default DownloadPage
